<script setup lang="ts">
import type { Brand } from "~/advertisorsData/shared/types";

const loading = ref(false);
interface Props {
  brand: Brand;
}

defineProps<Props>();

// function fetchData() {
// loading.value = true;
// apiService
//   .getAdvertiserCampaigns("Marinade")
//   .then((response) => {
//     campaigns.value = response.data?.featuredCampaigns ?? [];
//   })
//   .catch((e) => {
//     console.error(e);
//     errorMessage(e);
//   })
//   .finally(() => {
//     loading.value = false;
//   });
// }

const signWithX = async () => {
  try {
    const twitterResponse = await apiService.getTwitterLoginUrl();
    const { redirectUrl } = twitterResponse.data;
    window.location.href = redirectUrl;
  } catch (error) {
    console.error(error);
  }
};
</script>

<template>
  <div class="container-800 mt-8 lg:mt-14 lg:text-center font-semibold">
    <div class="text-4xl lg:text-6xl mb-8">
      Join the {{ brand.name }}<br />Ambassador Program
    </div>
    <div class="text-2xl mb-8 w-3/4 lg:w-full">
      Earn rewards for your posts on X.<br class="hidden lg:block" />
      Create your ambassador account on Evangelist today.
    </div>
    <SigninButton
      :style="{
        backgroundColor: brand.designSchema.primaryColor,
        borderRadius: brand.designSchema.borderRadius,
      }"
      class="btn text-white fill-white hover:!bg-black"
    >
      Check eligibility via <IconsTweetIcon />
    </SigninButton>
  </div>

  <PageLoader v-if="loading" class="mt-14" />
  <div v-else>
    <div
      v-if="(brand.campaigns?.length ?? 0) > 0"
      class="container-800 my-10 lg:mt-14"
    >
      <div class="container-800 mt-8 lg:mt-14 lg:text-center font-semibold">
        <div class="text-2xl mb-8 w-3/4 lg:w-full">
          Join these Ambassador Campaigns by {{ brand.name }}
        </div>
      </div>
    </div>

    <div class="container-950 mt-14 flex justify-center">
      <div class="flex flex-wrap items-center justify-between gap-6">
        <div class="flex flex-col gap-3 text-sm">
          <CampaignRow
            @click="signWithX"
            class="cursor-pointer"
            v-for="campaign in brand.campaigns"
            :key="campaign.id"
            :campaign="campaign"
            :landingPageMode="true"
            :customTotalBudgetText="
              brand.id === 'marinade'
                ? campaign.apyBonus > 0
                  ? 'Total Budget'
                  : 'Total Budget in 3 Campaigns'
                : 'Total Budget'
            "
          />
        </div>
      </div>
    </div>

    <div class="container-950 mt-14">
      <div class="p-6 py-8 lg:p-12 bg-white rounded-2xl">
        <ContentsHowDoesItWorksSlider />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
